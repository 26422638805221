@import "stylus/variables.styl"
@import "stylus/mixins.styl"
@import "stylus/layout.styl"

html
  box-sizing: border-box
  color: $color-primary
  font-size: calc-between-width($breakpoints.lg, $root-font-size.lg, $breakpoints.xl, $root-font-size.xl)
  font-weight: 300
  font-family: $main-font-family
  line-height: 1.4
  letter-spacing: 0.02em
  background-color: $palette.product.background
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-tap-highlight-color: transparent

  +width-lessthan($breakpoints.lg)
    font-size: "calc(%s * %s)" % ($root-font-size.md 1px)

  +width-lessthan(415)
    font-size: calc-between-width(375, 16, 414, 18)

body
  margin: 0
  padding: 0
