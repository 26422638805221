@font-face
  font-family 'Rosatom'
  src url('./Rosatom-Regular_W.eot')
  src url('./Rosatom-Regular_W.eot?#iefix') format('embedded-opentype'),
    url('./Rosatom-Regular_W.woff2') format('woff2'),
    url('./Rosatom-Regular_W.woff') format('woff'),
    url('./Rosatom-Regular_W.ttf') format('truetype'),
    url('./Rosatom-Regular_W.svg#RosatomWeb-Regular') format('svg')
  font-weight 400
  font-style normal

@font-face
  font-family 'Rosatom'
  src url('./Rosatom-Light_W.eot')
  src url('./Rosatom-Light_W.eot?#iefix') format('embedded-opentype'),
    url('./Rosatom-Light_W.woff2') format('woff2'),
    url('./Rosatom-Light_W.woff') format('woff'),
    url('./Rosatom-Light_W.ttf') format('truetype'),
    url('./Rosatom-Light_W.svg#RosatomWeb-Light') format('svg')
  font-weight 300
  font-style normal

@font-face
  font-family 'Rosatom'
  src url('./Rosatom-Bold_W.eot')
  src url('./Rosatom-Bold_W.eot?#iefix') format('embedded-opentype'),
    url('./Rosatom-Bold_W.woff2') format('woff2'),
    url('./Rosatom-Bold_W.woff') format('woff'),
    url('./Rosatom-Bold_W.ttf') format('truetype'),
    url('./Rosatom-Bold_W.svg#RosatomWeb-Bold') format('svg')
  font-weight 700
  font-style normal
