@import "~@angular/cdk/overlay-prebuilt.css"
@import "stylus/fonts.styl"
@import "stylus/mixins.styl"
@import "stylus/layout.styl"
@import "layout.styl"

// popup window
@import "ui-common/src/lib/components/popup-window/popup-window.global.styl"

// popup window
@import "ui-common/src/lib/components/feedback-form-popup/feedback-form-popup.global.styl"

+width-morethan($breakpoints.md)
  .dp-scrollbar-track
    width: 10px !important
    height: calc(100% - 2.1875rem * 2) !important
    overflow: visible !important

  .dp-scrollbar-track__menu
    width: 10px !important
    height: calc(100% - 2.1875rem * 2) !important
    overflow: visible !important

  .dp-scrollbar-track__menu_product
    width: 10px !important
    height: calc(100% - 2.1875rem * 2) !important
    overflow: visible !important

  .dp-scrollbar-thumb
    width: 10px !important
    height: 72px !important
    background: #FFFFFF !important
    box-shadow: 4px 4px 10px rgba(152, 155, 165, 0.14), 10px 10px 28px rgba(152, 155, 165, 0.1) !important
    border-radius: 3px !important

  .dp-scrollbar-thumb__menu
    width: 10px !important
    height: 72px !important
    background: #AFD4F6 !important
    box-shadow: 4px 4px 10px rgba(152, 155, 165, 0.14), 10px 10px 28px rgba(152, 155, 165, 0.1) !important
    border-radius: 3px !important

  .dp-scrollbar-thumb__menu_product
    width: 10px !important
    height: 72px !important
    background: #ffffff !important
    box-shadow: 4px 4px 10px rgba(152, 155, 165, 0.14), 10px 10px 28px rgba(152, 155, 165, 0.1) !important
    border-radius: 3px !important

  .dp-popup-scrollbar-track
    position: relative
    width: 12px !important
    height: calc(100% - 2.1875rem * 2) !important
    overflow: visible !important

    &::after
      display: block
      content: ''
      width: 1px
      height: 100%
      position: absolute
      left: calc(50% - 0.5px)
      top: 0
      background: rgba(#25333E, 0.1)
      z-index: -1

  .dp-popup-scrollbar-thumb
    width: 12px !important
    height: 72px !important
    background: #FFFFFF !important
    box-shadow: 4px 4px 10px rgba(152, 155, 165, 0.14), 10px 10px 28px rgba(152, 155, 165, 0.1) !important
    border-radius: 6px !important


+width-lessthan($breakpoints.md)
  .dp-scrollbar-track__menu
    display: none

  .dp-scrollbar-track__menu_product
    display: none


// Overlays
.sidebar-overlay-wrapper
  z-index: 996

.logo-overlay-wrapper
  z-index: 997

.burger-overlay-wrapper
  z-index: 998

.global-overlay-wrapper
  z-index: 999
