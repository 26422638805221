@import "stylus/layout.styl"
@import "stylus/variables.styl"

.dp-feedback-form-overlay
  &_main
    background: $palette.base.overlayBackground

.dp-feedback-form-panel
  justify-content: center
  align-items: center
  box-sizing border-box
  max-width unset !important
  margin: 0
  width: auto
  height: auto
  overflow: visible


/* Адаптивность */

// 768 <= width < 1024
+width-lessthan($breakpoints.md)
  .dp-feedback-form-panel
    margin-left: auto
    margin-right: auto

// 768 <= width < 1024
+width-lessthan($breakpoints.sm)
  .dp-feedback-form-panel
    margin: 0
    width: 100vw
    height: 100%

