@import "stylus/layout.styl"

.dp-popup-window-overlay
  & + .cdk-global-overlay-wrapper
    justify-content  flex-start !important

.dp-popup-window-panel
  box-sizing border-box
  max-width unset !important
  margin: 2.5rem 0 1.875rem "calc(%s + %s + 2px)" % ($menu-toggle-width ($sidebar-width.xl))
  overflow: visible

  .product-info-popup
    margin 0
    padding: 0
    width 42.1875rem
    max-height calc(100vh - 4.375rem)
    overflow: visible

  .mat-dialog-content
    margin: 0


/* Адаптивность */

// 1024 <= width < 1280
+width-lessthan($breakpoints.lg)
  .dp-popup-window-panel
    margin-left: "calc(%s + 2px)" % ($sidebar-width.md)

// 768 <= width < 1024
+width-lessthan($breakpoints.md)
  .dp-popup-window-panel
    margin-left: auto
    margin-right: auto

// 768 <= width < 1024
+width-lessthan($breakpoints.sm)
  .dp-popup-window-panel
    margin: 0
    width: 100vw
    height: 100%

    .mat-dialog-container
      width: 100vw
      height: 100%
      max-height: unset

